.wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  width: 80px !important;
  height: auto;
  margin-bottom: 30px;
}

.form {
  width: 35%;
}

.registerText {
  text-align: center;
  margin-top: 10px;
}

.registerText:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .form {
    width: 100%;
  }
}

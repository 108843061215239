.chatHeader {
  background-color: #ddd;
  position: sticky;
  height: 100px;
  width: 100%;
  border-bottom: 5px solid #ccc;
}

.chatHeaderData {
  padding: 10px;
}

.chatMessages {
  height: calc(100vh - 260px); /* header 90px, chat header 100px, input 70px */
}

.chatInputWrapper {
  background-color: #ddd;
  height: 70px;
  width: 100%;
  padding: 1em;
}
